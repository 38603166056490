import React from "react"
import {useStaticQuery, Link} from "gatsby"
import {Container, Row, Col} from "reactstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"


const NotFoundPage = () => {
  // const data = useStaticQuery(graphql`
  //   query FourOFourQuery {        
  //       banner: file(relativePath: {eq: "images/logo.png"}, sourceInstanceName: {eq: "home"}) {
  //         childImageSharp {
  //           fluid(quality:100) {
  //             ...GatsbyImageSharpFluid
  //           }
  //         }
  //       }
  //   }
  // `)  

  // const banner = data.banner.childImageSharp.fluid

  return (
    <Layout>
      <SEO title="404: Not found" />
      <Container>
        <Row  className="py-5 min-vh-100 align-items-center">            
            <Col>
              <h1>Sorry,</h1>
              <p>We could not find what you are looking for. Maybe the link has moved. Try going <b>back</b> or go to <Link to="/"><b>Home</b></Link></p>
            </Col>
          </Row>
      </Container>   
      
    </Layout>
)}

export default NotFoundPage
